// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.

import * as Sentry from '@sentry/astro'

Sentry.init({
  dsn: import.meta.env.PUBLIC_SENTRY_DSN,
  release: import.meta.env.PUBLIC_SENTRY_RELEASE,
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    // Generic error code from errors outside the security sandbox
    // You can delete this if using raven.js > 1.0, which ignores these automatically.
    'Script error.',
    // loading chunk
    'Error: Loading chunk',
    'Loading chunk',
    // resizeobsever is not helpful
    'ResizeObserver loop limit exceeded',
    // unhandled sentry error related to client side latency
    'UnhandledRejection: Non-Error promise rejection captured with value: delay_connect',
    'Non-Error promise rejection captured',
    'Unexpected end of JSON input',
    // ignore all the play errors
    /AbortError: /,
    /NotAllowedError: /,
    // ignore errors linked to ssr caching
    'TypeError: Failed to fetch dynamically imported module:',
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
  tunnel: '/sentry-tunnel/',
  beforeSend(event) {
    if (isInjectedCode(event)) {
      return null
    }
    if (event.request?.url) {
      event.request.url = normalizeUrl(event.request.url)
    }
    return event
  },
})

// Replace dynamic slugs
function normalizeUrl(url: string) {
  return url
    .replace(/\/blog\/category\/[^/]+/, '/blog/category/*')
    .replace(/\/blog\/tag\/[^/]+/, '/blog/tag/*')
    .replace(/\/blog\/[^/]+/, '/blog/*')
    .replace(/\/customers\/[^/]+/, '/customers/*')
}

/**
 * disable errors originating from injected scripts such as Google Tag Manager
 * NOTE: function comes from https://github.com/getsentry/sentry-javascript/issues/3147#issuecomment-1782504804
 */
function isInjectedCode(event?: Sentry.Event): boolean {
  const frames = event?.exception?.values?.[0]?.stacktrace?.frames

  if (!frames || frames.length === 0) return false

  const firstFrame = frames[0]
  if (firstFrame.filename === '<anonymous>') {
    return true
  }

  const lastFrame = frames[frames.length - 1]
  if (
    typeof lastFrame.filename === 'string' &&
    (lastFrame.filename === window.location.pathname ||
      (lastFrame.filename.startsWith(window.location.origin) &&
        // static file should not be considered as injected code. We use react-script currently, and all js-generated files are in this "static" directory.
        !lastFrame.filename.includes('/static/')))
  ) {
    return true
  }

  if (
    frames.some(
      (frame) =>
        typeof frame.filename === 'string' &&
        (frame.filename.startsWith('http://www.googletagmanager.com') ||
          frame.filename.startsWith('https://www.googletagmanager.com') ||
          frame.filename.startsWith('https://googleads.')),
    )
  ) {
    return true
  }

  if (frames.some((frame) => frame.filename?.startsWith('chrome-extension://'))) {
    return true
  }

  return false
}
